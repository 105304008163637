import React, { Component } from 'react';
import { LookupEdit } from '../../../Components/FormComponents/LookupEdit/LookupEdit';

export default class CarrierManager extends Component {

  render(){
    let carrierColumns = [
      { field: "carrier_id", header: "ID", ignore: true, width: '50px' },
      { field: "carrier_name", header: "Name", sortable: true, type: "string", width: '300px' },
      { field: "demo_visible", header: "Demo Visible", type: "boolean", width: '100px' }
    ]

    let carrierCodeColumns = [
      { field: "carrier_code_id", header: "ID", ignore: true, width: '50px' },
      { field: "carrier_specific_code", header: "Code", sortable: true, type: "string", width: '40%' },
    ]

    return(
      <div className="p-grid">
        <div className="p-col-6">
          <LookupEdit header="Carriers" idField="carrier_id" tblName="medical_library_carriers" columns={carrierColumns} />
        </div>

        <div className="p-col-6">
          <LookupEdit header="Carrier Codes" idField="carrier_code_id" tblName="medical_library_carrier_codes" columns={carrierCodeColumns} />
        </div>
      </div>
    );
  }

}
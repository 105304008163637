import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { BooleanDropdown } from '../BooleanDropdown';
import { LookupDropdown } from '../LookupDropdown';

export class EditDialog extends Component{
	constructor( props ){
		super( props );

		this.state = {
			visible: false,
			editMode: false
		}

		this.data = {};

		this.handleValueChange = this.handleValueChange.bind( this );
		this.showDialog = this.showDialog.bind( this );
		this.onSave = this.onSave.bind( this );
		this.onHide = this.onHide.bind( this );
	}

	componentDidUpdate( prevProps, prevState ){
		// we only wanna watch for the data to change in the state
		if( this.state !== prevState ){
			console.log( this.state );
		}
	}

	showDialog( header, data, editMode ){
		console.log( editMode );
    this.data = {...data};
		this.setState( {
			header: header,
			data: data,
			editMode: editMode,
			visible: true,
			isLoaded: true
		} );
	}

	onSave(){
		this.props.onSave( this.data );
		this.setState( {visible: false} );
	}

	onHide(){
		this.setState( {visible: false} );
	}

	handleValueChange( key, value ){
		if( this.data )
			this.data[key] = value;
	}

	render(){
		let selectedValue = {};

		const footer = (
	    <div>
	      <Button label="Save" className="p-button-success" icon="pi pi-check" onClick={this.onSave} />
	      <Button label="Cancel" icon="pi pi-times" onClick={this.onHide} className="p-button-secondary" />
	    </div>
    );

    let data = {};
    if( this.state.editMode )
    	data = this.state.data;

    let body = this.props.fields.map( (col, i) => {
			// return <Column key={col.field} field={col.field} header={col.header} style={{width:'1em'}} />
			if( !col.ignore ){
				switch( col.type ){
					case "currency":
						return (
							<div className="p-col-12" key={col.field}>
								<b>{col.header}:</b><br />
								<InputText
									defaultValue={data[col.field]}
									onChange={(e) => this.handleValueChange( col.field, e.target.value )} /></div>
						);

					case "lookupDropdown":
						selectedValue = {label:data[col.dropdown.label], value:data[col.dropdown.selField]};
						return (
							<div className="p-col-12" key={col.field}>
								<b>{col.header}:</b><br />
								<LookupDropdown
									name={col.field}
									selected={selectedValue}
									tblName={col.dropdown.table}
									labelField={col.dropdown.label}
									valueField={col.dropdown.value}
									onChange={(value) => this.handleValueChange( col.field, value )} />
							</div>
						);

					case "boolean":
						selectedValue = {label:(data[col.field] > 0)?"Yes":"No", value:data[col.field]};
						return (
							<div className="p-col-12" key={col.field}>
								<b>{col.header}:</b><br />
								<BooleanDropdown
									selected={selectedValue}
									label="Yes or No"
									onChange={(value) => this.handleValueChange( col.field, value )} /></div>
						);

					default:
						return (
							<div className="p-col-12" key={col.field}>
								<b>{col.header}:</b><br />
								<InputText defaultValue={data[col.field]} onChange={(e) => this.handleValueChange( col.field, e.target.value )} /></div>
						);
				}
			}

		} );

    return(
			<Dialog
				key={"dialog"+this.props.tblName}
				header={this.state.header}
				visible={this.state.visible}
				style={{width:'50vw'}}
				modal={true}
				onHide={(e) => this.setState( {visible: false} )}
				footer={footer}>
				<div className="p-grid p-fluid">
					{body}
          <div style={{height:"150px"}}></div>
				</div>
			</Dialog>
		);
	}

}
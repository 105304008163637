import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { lookupService } from '../../Service/lookupservice.js';
import uuid from 'react-uuid';


export class LookupDropdown extends Component{
	constructor( props ){
		super( props );

		this.state = {
			isLoaded: false,
			items: [],
			error: null,
			selected: this.props.value
		}

		this.handleChange = this.handleChange.bind( this );
		this.importOptions = this.importOptions.bind( this );
	}

	componentDidMount(){
		lookupService.getLookupData( this.props.tableName, null, this.props.labelField, this.props.valueField )
  	.then( response => {
      // console.log( response.data );
  		this.setState( {items:response.data,isLoaded:true} )
  	} )
  	.catch( error => {console.log( error )} );
	}

	componentDidUpdate( prevProps, prevState ){
		// we only wanna watch for the data to change in the state
		if( prevState.selected !== this.props.value )
			this.setState( {selected:this.props.value} );
	}

	handleChange( event ){
		this.setState( {selected: event.value} );
		this.props.onChange( event.value );
	}

	importOptions(){
		let options = [];
		if( this.state.items ){
			for( let x=0; x<this.state.items.length; x++ ){
				let label = "";
				// look for a + to denote a concatenated field
				if( this.props.labelField ){
					if( this.props.labelField.indexOf( "+" ) > -1 ){
						let parts = this.props.labelField.split( '+' );

						for( let t=0; t < parts.length; t++ ){
							if( t > 0 && this.state.items[x][parts[t]].length )
								label += " - ";

							label += this.state.items[x][parts[t]];
						}

					}
					else
						label = ( this.state.items[x][this.props.labelField] === "" )?"Direct":this.state.items[x][this.props.labelField];
				}

				options.push( {label: label, value: this.state.items[x][this.props.valueField]} )
			}
		}

		return options;
	}

	render(){
		const { error, isLoaded } = this.state;
    // console.log( "selected value: " + this.state.selected );
    // console.log( this.props.value );

		if( error ){
			return <div>Error: {error.message}</div>;
		}
		else if( !isLoaded ){
			return <div></div>;
		}
		else{
			return(
				<Dropdown
					key={uuid()}
					filter={true}
          optionLabel="label"
          optionValue="value"
					value={this.state.selected}
					options={this.importOptions()}
          onChange={this.handleChange}
          onBlur={this.onBlur}
					placeholder={this.props.label}
					disabled={this.props.disabled}
					filterBy="label, value"/>
			);
		}
	}
}
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {InputText} from 'primereact/inputtext'
import { userService } from '../../Service/UserService';

export default class UserTable extends Component {
  constructor( props ){
    super( props );

    this.state = {
      isLoaded: false,
      selectedUser: -1
    };

    this.handleRowSelect = this.handleRowSelect.bind( this );
  }

  componentDidMount(){
    this.getUsers();
  }

  getUsers(){
    userService.getUserList()
    .then( response=> {
      console.log( response );
      let userData = response;

      if( Array.isArray( userData ) )
      {
        for( let x=0; x < userData.length; ++x )
        {
          userData[x].fullname = userData[x].last_name + ", " + userData[x].first_name;
          userData[x].emailVerified = ( userData[x].has_verified_email == "0"?"Yes":"No" );
          userData[x].requirePwChange = (userData[x].change_pw_flag == "1" ? "Yes" : "No");
        }
      }

      this.setState( { users: userData, isLoaded: true } );
    } )
    .catch( error => { console.log( error ) } );
  }

  handleRowSelect( e ){
    console.log( e.data );
    this.props.onSelectUser( e.data );
  }

  handleSelectionChange(){

  }

  render() {
    if( this.state.isLoaded )
    {
      const header = (
        <div style={{ 'textAlign': 'left' }}>
          <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
          <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="User Search" size="50" />
        </div>
      );

      const footer = (
        <div style={{ 'textAlign': 'left' }}>
          {this.state.users.length} Registered User{(this.state.users.length > 1)?"s":""} in System
        </div>
      );

      return (
        <DataTable
          header={header}
          footer={footer}
          autoLayout={true}
          value={this.state.users}
          selectionMode="single"
          selection={this.state.selectedUser}
          onRowSelect={this.handleRowSelect}
          onSelectionChange={this.handleSelectionChange}
          globalFilter={this.state.globalFilter} emptyMessage="No records found">

          <Column field="user_id" header="ID" />
          <Column field="fullname" header="Full Name" />
          <Column field="username" header="Username" />
          <Column field="email" header="Email" />
          <Column field="status_name" header="Status" />
          <Column field="role_name" header="Role" />
          <Column field="last_login" header="Last Login" />
          <Column field="created" header="Date Created" />
          <Column field="emailVerified" header="Verified Email" />
          <Column field="requirePwChange" header="Require PW Change" />

        </DataTable>
      );
    }
    else return( <div></div> );
  }
};
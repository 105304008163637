import { filterConstants } from '../Constants/filter.constants';

export function filter(state = {}, action) {
  switch (action.type) {
    case filterConstants.SELECT_CARRIER:
      return Object.assign( {}, state, { carrier: action.carrier } );

    case filterConstants.SELECT_TRUST:
      return Object.assign( {}, state, { trust: action.trust } );

    case filterConstants.SELECT_PLANDATE:
      return Object.assign( {}, state, { plandate: action.plandate } );

    case filterConstants.SELECT_NETWORK:
      return Object.assign( {}, state, { network: action.network } );

    case filterConstants.SELECT_FAILURE:
      return action.error;

    default:
      return state
  }
}
import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { filterService } from '../../Service/filter';

export default class FilterDropdown extends Component{
	constructor( props ){
		super( props );

		this.state = {
			isLoaded: false,
			items: [],
			error: null,
			selected:null,
			filterObj:this.props.filterObj
		}

		this.handleChange = this.handleChange.bind( this );
		this.importOptions = this.importOptions.bind( this );
	}

	componentDidMount(){
		if( !this.props.options ){
			this.importOptions();
		}
		else{
			this.setState( {isLoaded: true} );
		}
	}

	componentDidUpdate( prevProps, prevState, snapshot ){
		if( prevProps.filterObj !== this.props.filterObj ){
			//console.log( "-----------------------------------------------------------------------" );
			//console.log( prevProps.filterObj );
			//console.log( this.state.filterObj );
			//console.log( "-----------------------------------------------------------------------" );
			this.importOptions();
		}
	}

	importOptions(){
		if( this.props.depend === "none" || this.props.filterObj[this.props.depend] ){
			filterService.getFilterData( this.props.filterObj )
			.then( response => {
				this.setState( {isLoaded:true, items: response.data} );
			} )
			.catch( error => {
				this.setState( {isLoaded:false, items: null} );
				console.log( error );
			} );
		}
	}

	getOptions(){
		let options = [];
		if( this.props.options ){
			options = this.props.options;
			options.unshift( {label: this.props.label, value: ""} );
		}
		else{
			for( let x=0; x<this.state.items.length; x++ ){
				let label = ( this.state.items[x][this.props.nameField] === "" )?"Direct":this.state.items[x][this.props.nameField];
				options.push( {label: label, key: this.state.items[x][this.props.idField]} )
			}
		}

		return options;
	}

	handleChange( event ){
		let val = event.value;
		this.setState( {selected: val} );
		this.props.callbackFunc( this.props.name, val );
	}

	render(){
		const { error, isLoaded } = this.state;

		if( error ){
			return <div>Error: {error.message}</div>;
		}
		else if( !isLoaded ){
			return <div></div>;
		}
		else{
			return(
				<Dropdown
					key={this.props.name}
					filter={true}
					optionLabel="label"
					value={this.state.selected}
					options={this.getOptions()}
					onChange={this.handleChange}
					placeholder={this.props.label}
					filterBy="label, key"
					style={{marginRight: '10px'}} />
			);
		}
	}
}

import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { lookupService } from '../../Service/lookupservice.js';
import uuid from 'react-uuid';

export class FilterInput extends Component{

  constructor( props ){
    super( props );

    this.state ={
      value: this.props.value,
      options: null,
      isLoaded: false
    }

    this.handleValueChange = this.handleValueChange.bind( this );
  }

  componentDidMount() {
    lookupService.getLookupData(this.props.tableName, null, this.props.labelField, this.props.labelField)
      .then(response => {
        // console.log( response.data );
        // this.setState({ items: response.data, isLoaded: true })
        let options = [];

        response.data.map( (item) => options.push( item[this.props.labelField] ) );
        this.setState( {options: options, isLoaded:true} );
      })
      .catch(error => { console.log(error) });
  }

  handleValueChange(value) {
    this.setState({ value: value });
    this.props.onChange( value );
  }

  render(){
    const val = (this.state.value ? this.state.value : "");
    let dataList = "";
 
    if( this.state.isLoaded )
    {
      dataList = (
          <datalist id={this.props.dataListName}>
            {this.state.options.map( (opt) => (<option value={opt} key={uuid()} />) )}
          </datalist>
      );
    }
    
    return (
      <div><InputText
        id={this.props.id}
        value={val}
        list={this.props.dataListName}
        onChange={(e) => this.handleValueChange(e.target.value)}
        onBlur={this.props.onBlur}
        disabled={this.props.disabled} />
        {dataList}
      </div>);
  }
}
import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { PlanEditor } from "../PlanEditor/planeditor.js";

export class PlanDialog extends Component{
	constructor( props ){
		super( props );

		this.state = {
			visible: false,
			isLoaded: false,
			header:""
		}

		this.plan = null;
		this.delta = {};
		this.terms = {};

		this.showDialog = this.showDialog.bind( this );
		this.dialogBodyTemplate = this.dialogBodyTemplate.bind( this );
		this.handleValueChange = this.handleValueChange.bind( this );
		this.onHide = this.onHide.bind( this );
		this.onSave = this.onSave.bind( this );
	}

	showDialog( data, header ){
		this.plan = data;
		this.setState( {visible: true, isLoaded: true, header: header} );
	}

	handleValueChange( key, value, entryId, isTerm, batchEdit ){
		if( isTerm ){
			if( this.terms ){
				this.terms[key] = { name: key, term: value, entryId: entryId};
			}
		}
		else{
			if( this.delta ){
				this.delta[key] = value
			}
		}
	}

	dialogBodyTemplate(){
    let data = this.props.plans[0];
    
		return(
			<div>
        <PlanEditor planId={data.plan_id} categoryMap={this.props.categoryMap} generalMap={this.props.generalMap} batchEdit={false} disabled={false} onSave={this.onSave} />
			</div>
		);
	}

	onHide(event) {
  	this.setState( {visible: false} );
  }

  onSave( delta ){
  	this.props.onSave( delta );
  	this.onHide();
  }

	render(){
		const footer = (
	    <div>
	      {/* <Button label="Save" className="p-button-success" icon="pi pi-check" onClick={this.onSave} /> */}
	      <Button label="Cancel" icon="pi pi-times" onClick={this.onHide} className="p-button-secondary" />
	    </div>
    );

		let body = null;

		if( this.state.isLoaded )
    	body = this.dialogBodyTemplate();

		return(
			<Dialog
				header={this.state.header}
				visible={this.state.visible}
				style={{width:'85vw'}}
				modal={true}
				onHide={(e) => this.setState( {visible: false} )}
				footer={footer}>
				<div style={{height: '70vh', overflowY:'auto', overflowX:'hidden'}}>
					{body}
				</div>
			</Dialog>
		);
	}
}
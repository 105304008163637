import { config as Config } from '../Config/config';
import axios from 'axios';

export const managePlanService = {
	getPlans,
	savePlans,
	addPlan,
	clonePlans
};
//-----------------------------------------------------------------------------
function getPlans( aIds ){
  let strUrl = Config.apiUrl + "?res=plan&ids=" + JSON.stringify( aIds );
	console.log( strUrl );

	return axios.get( strUrl );
}
//-----------------------------------------------------------------------------
function savePlans( idArray, delta ){
  let strUrl = Config.apiUrl + "?res=plan";
	console.log( strUrl );

	const config = {
	  headers: {
	    'Content-Type': 'application/x-www-form-urlencoded'
	  }
  }

  let pack = {
    ids: idArray,
  };

  if (delta) pack.delta = delta;
  
  return axios.patch(strUrl, pack, config );
}

function addPlan( data ){

}
//-----------------------------------------------------------------------------
function clonePlans( idArray, delta ){
  let strUrl = Config.apiUrl + "?res=plan";
	console.log( strUrl );

	const config = {
	  headers: {
	    'Content-Type': 'application/x-www-form-urlencoded'
	  }
	}

	let pack = {
		ids: idArray,
	};

	if( delta ) pack.delta = delta;

	console.log( "pack:" );
	console.log( pack );

	return axios.post( strUrl, pack, config );
}
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { EditDialog } from '../../../Components/FormComponents/LookupEdit/EditDialog';
import { mccService } from 'Service/mccService';

export class MccEntryTable extends Component{
  constructor( props ) {
    super( props );

    this.state = {
			mode: null,
			selected: null,
      first: 0,
      rows: 50,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: {
        category_name: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        term: { value: '', matchMode: 'contains' }
      },
      applyFilters: false,
      totalRecords: 0,
      data: null,
      loading: false
		}

		this.dialog = React.createRef();
		this.handleClick = this.handleClick.bind( this );
		this.dialogCallback = this.dialogCallback.bind( this );
    this.onPage = this.onPage.bind( this );
    this.onSort = this.onSort.bind( this );
    this.onFilter = this.onFilter.bind( this );
    this.clearFilter = this.clearFilter.bind( this );
    this.initFilters = this.initFilters.bind( this );
    this.renderHeader = this.renderHeader.bind( this );
    this.applyFilters = this.applyFilters.bind( this );
  }

  async getEntries(){
    this.setState( {loading: true} );
    const options = {
      page: this.state.page + 1,
      count: this.state.rows,
      sortOrder: this.state.sortOrder,
      sortField: this.state.sortField,
      filterField: this.state.filterField,
      filterValue: this.state.filterValue
    };

    console.log( this.state.applyFilters );

    const resp = await mccService.getMCCEntries( options, this.state.applyFilters?this.state.filters:null );
    this.setState( {data: resp.entries, loading:false} );
  }

  async getEntryCount() {
    const count = await mccService.getMccCount();
    this.setState( {totalRecords: parseInt(count.count)} );
  }

  componentDidMount() {
    this.setState( {loading:true} );
    this.getEntryCount();

    this.getEntries();
  }

  componentDidUpdate( prevProps, prevState ) {
    if( prevState.page !== this.state.page ) {
      this.getEntries();
    }

    if( prevState.sortField !== this.state.sortField || prevState.sortOrder !== this.state.sortOrder ){
      this.getEntries();
    }

    if( prevState.applyFilters !== this.state.applyFilters ) {
      this.getEntries();
    }

  }

  onPage( event ) {
    console.log( event );
    this.setState( {...event} );
  }

  onSort( event ){
    console.log( event );
    this.setState( {...event} );
  }

  onFilter( event ){
    event['first'] = 0;
    console.log( event );
    this.setState( {...event} );
  }

  async dialogCallback( delta ){
    console.log( delta );
    if( this.state.mode === "Edit" ){
      const resp = mccService.updateMccEntry( delta.mcc_entry_id, delta );
	  }
	  else{
	  	const resp = mccService.addMccEntry( delta );
	  }
  }

  initFilters() {
    this.setState( {
      filters: {
        category_name: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        term: { value: '', matchMode: 'contains' }
      },
    } );
  }

  clearFilter() {
    this.setState( {applyFilters: false} );
    this.initFilters();
  }

  applyFilters(){
    this.setState( {applyFilters: true} );
    console.log( this.state.filters );
  }

  renderHeader() {
    return (
      <div style={{display:'flex'}}>
        <h2 style={{flexGrow: 1}}>Medical Care Coverage Terms</h2>
        <Button
          type="button"
          icon="pi pi-filter"
          label="Apply"
          onClick={this.applyFilters}
          style={{marginRight: 5}}
        />
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined p-button-secondary"
          onClick={this.clearFilter}
        />
      </div>
    );
  }

  handleClick( label ) {
    this.setState( {mode:label} );
  	this.dialog.current.showDialog( label + " Medical Care Coverage Term", this.state.selected, (label === "Edit") );
  }

  render(){
    const entryColumns = [
      { field: "mcc_entry_id", header: "ID", ignore: true, sortable: true, type: "number", width: '50px'},
      { field: "category_name", filter: true, filterPlaceholder: "Search", header: "Category", sortable: true, type: "string", width: '150px'},
      { field: "name", filter: true, filterPlaceholder: "Search", header: "Name", sortable: true, type: "string", width: '150px'},
      { field: "term", filter: true, filterPlaceholder: "Search", header: "Term", sortable: true, type: "string", width: '150px'},
      { field: "abbrv", header: "Abbr", sortable: true, type: "string", width: '50px' }
    ];

    const header = this.renderHeader();

    let columns = entryColumns.map( (col) => {
      return (
        <Column key={col.field} 
          filter={col.filter?true:null}
          filterPlaceholder={col.filterPlaceholder}
          filterMatchMode="contains"
          field={col.field}
          header={col.header} 
          sortable={col.sortable} 
          style={{width:col.width}} 
        />
      );
    } );
    return(
      <div>
        <EditDialog
          header="Medical Care Coverate Terms"
          fields={entryColumns}
          ref={this.dialog}
          callback={this.dialogCallback}
          onSave={this.dialogCallback}
        />
        <DataTable
          value={this.state.data}
          header={header}           
          scrollable={true}
          scrollHeight={"680px"}
          selectionMode="single"
          selection={this.state.selected}
          lazy
          paginator 
          rows={50}
          page={this.state.page}
          first={this.state.first}
          sortField={this.state.sortField}
          sortOrder={this.state.sortOrder}
          filters={this.state.filters}
          loading={this.state.loading}
          totalRecords={this.state.totalRecords}
          onPage={this.onPage}
          onSort={this.onSort}
          onFilter={this.onFilter}
          onSelectionChange={(e) => {
            this.setState({ selected: e.value });
          }}
          style={{
            height: "858px",
            overflowY: "auto",
            border: "1px solid #C8C8C8",
          }}
          >
            {columns}
        </DataTable>
        <div style={{ padding: "10px", border: "1px solid #C8C8C8" }}>
            <Button
              icon="pi pi-plus"
              className="p-button-secondary"
              onClick={() => this.handleClick("Add")}
            />
            <Button
              icon="pi pi-pencil"
              className="p-button-secondary"
              disabled={this.state.selected === null}
              style={{ float: "right" }}
              onClick={() => this.handleClick("Edit")}
            />
          </div>
      </div>
    );
  }
}
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { lookupService } from '../../../Service/lookupservice';
import { EditDialog } from './EditDialog.js';

export class LookupEdit extends Component{
	constructor( props ){
		super( props );

		this.state = {
			mode: null,
			selected: null
		}

		this.dialog = React.createRef();
		this.handleClick = this.handleClick.bind( this );
		this.dialogCallback = this.dialogCallback.bind( this );
	}

	componentDidMount(){
  	this.getData();
  }

  getData(){
  	lookupService.getLookupData( this.props.tblName )
  	.then( response => {
  		this.setState( {data:response.data} )
  	} )
  	.catch( error => {console.log( error )} );
  }

  handleClick( label ){
  	this.setState( {mode:label} );
  	this.dialog.current.showDialog( label + " " + this.props.header, this.state.selected, (label === "Edit") );
  }

  dialogCallback( delta ){
  	if( this.state.mode === "Edit" ){
	  	lookupService.updateLookupData(
	  		this.props.tblName,
	  		this.props.idField,
	  		this.state.selected[this.props.idField],
	  		delta )
	  	.then( response => {
	  		this.getData();
	  	} )
	  	.catch( error => {console.log( error )} );
	  }
	  else{
	  	lookupService.addLookupData(
	  		this.props.tblName,
	  		this.props.idField,
	  		delta )
	  	.then( response => {
	  		this.getData();
	  	} )
	  	.catch( error => {console.log( error )} );
	  }
  }

	render(){
		if(  this.state.data ){

			let columns = this.props.columns.map( (col, i) => {
				return <Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{width:col.width}} />
			} );

			return (
        <div>
          <EditDialog
            key={this.props.columns.field}
            header={this.props.header}
            fields={this.props.columns}
            ref={this.dialog}
            tblName={this.props.tblName}
            callback={this.dialogCallback}
            onSave={this.dialogCallback}
          />

          <DataTable
            value={this.state.data}
            header={this.props.header}
            scrollable={true}
            scrollHeight={"250px"}
            selectionMode="single"
            selection={this.state.selected}
            onSelectionChange={(e) => {
              this.setState({ selected: e.value });
            }}
            style={{
              height: "350px",
              overflowY: "auto",
              border: "1px solid #C8C8C8",
            }}
          >
            {columns}
          </DataTable>
          <div style={{ padding: "10px", border: "1px solid #C8C8C8" }}>
            <Button
              icon="pi pi-plus"
              className="p-button-secondary"
              onClick={() => this.handleClick("Add")}
            />
            <Button
              icon="pi pi-pencil"
              className="p-button-secondary"
              disabled={this.state.selected === null}
              style={{ float: "right" }}
              onClick={() => this.handleClick("Edit")}
            />
          </div>
        </div>
      );
		}
		else{
			return(
				<div style={{width:'567px',height:'300px'}}>
					<ProgressSpinner mode="indeterminate" style={{marginLeft:'213px', marginTop: '80px'}} />
				</div>
			);
		}
	}
}
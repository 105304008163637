import { config as Config } from '../Config/config';
import axios from 'axios';

export const lookupService = {
	getLookupData,
	updateLookupData,
	addLookupData
};

function getLookupData( tableName, arg, labelField, valueField ){
	let args = "&tbl=" + tableName;

	if( arg ){
		args += "&arg=" + arg;
  }
  
  if( labelField )
    args += "&lbl=" + labelField;

  if( valueField )
    args += "&val=" + valueField;

	let strUrl = Config.apiUrl + "?res=db" + args;
	console.log( strUrl );

	return axios.get( strUrl );
}

function updateLookupData( tableName, idField, id, data ){
	let strUrl = Config.apiUrl + "?res=db"
	console.log( strUrl );

	const config = {
	  headers: {
	    'Content-Type': 'application/x-www-form-urlencoded'
	  }
	}

	return axios.patch( strUrl, {
		tableName: tableName,
		idField: idField,
		id: id,
		values: data
	}, config );
}

function addLookupData( tableName, idField, data ){
	let strUrl = Config.apiUrl + "?res=db"
	console.log( strUrl );

	const config = {
	  headers: {
	    'Content-Type': 'application/x-www-form-urlencoded'
	  }
	}

	return axios.post( strUrl, {
		tableName: tableName,
		idField: idField,
		values: data
	}, config );
}
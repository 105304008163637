import React, { Component } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { userService } from '../../Service/UserService';

export class UserToolbar extends Component {

  constructor(props) {
    super(props);

    this.onAddUser = this.onAddUser.bind( this );
  }

  onAddUser(){
    this.props.onAddUser();
  }

  render(){
    const rightPanel = (
      <Button label="Add User" icon="pi pi-plus" style={{ marginRight: '.50em' }} onClick={this.onAddUser} />
    );

    return( 
      <Toolbar right={rightPanel} />
    );
  }
}

import { config } from '../Config/config.js';
import { handleServiceResponse } from './ServiceDataHandler';

export const userService = {
  login,
  logout,
  getProfile,
  getUserList,
  addNewUser,
  updateUser,
  changePass
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ username, password })
  };

  return fetch(`${config.apiUrl}?res=login`, requestOptions)
    .then(handleServiceResponse)
    .then( auth => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      if( auth.message === "successful login") {
        console.log( auth.user );
        localStorage.setItem( 'user', JSON.stringify( auth.user ) );

        return auth.user;
      }
    });
}

function logout() {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  };

  if( localStorage.getItem( 'user' ) ){
    fetch( `${config.apiUrl}?res=login`, requestOptions )
      .then( handleServiceResponse )
      .then( () => {
        // remove user from local storage to log user out
        localStorage.removeItem( 'user' );
      } );
  }
}

function getProfile(){
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  };

  return fetch( `${config.apiUrl}?res=profile`, requestOptions ).then( handleServiceResponse );
}

function getUserList(){
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  };

  return fetch( `${config.apiUrl}?res=system&sub=users`, requestOptions ).then( handleServiceResponse );
}

function addNewUser( user ){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ user })
  };

  return fetch( `${config.apiUrl}?res=system&sub=user`, requestOptions ).then(handleServiceResponse);
}

function updateUser( user ) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ user })
  };

  return fetch( `${config.apiUrl}?res=system&sub=user`, requestOptions).then(handleServiceResponse );
}

function changePass( data ) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ data })
  };

  return fetch(`${config.apiUrl}?res=system&sub=pass`, requestOptions).then(handleServiceResponse);
}
import React, { Component } from 'react';
import { LookupEdit } from '../../../Components/FormComponents/LookupEdit/LookupEdit';

export default class PlanTypeManager extends Component {

  render() {
    let planTypeColumns = [
      { field: "plan_type_id", header: "ID", ignore: true, width: '50px' },
      { field: "plan_type_name", header: "Name", sortable: true, type: "string", width: '150px' }
    ]

    let metalColumns = [
      { field: "metal_id", header: "ID", ignore: true, width: '50px' },
      { field: "metal_name", header: "Name", sortable: true, type: "string", width: '150px' }
    ]

    return (
      <div className="p-grid">
        <div className="p-col-6">
          <LookupEdit header="Plan Types" idField="plan_type_id" tblName="medical_library_plan_type_lu" columns={planTypeColumns} />
        </div>
        <div className="p-col-6">
          <LookupEdit header="Metal" idField="metal_id" tblName="medical_library_metal_lu" columns={metalColumns} />
        </div>
      </div>
    );
  }

}
import { config } from '../Config/config.js';
import { handleServiceResponse } from './ServiceDataHandler';

export const menuService = {
  getMenu,
  getMenuList
};

function getMenu() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch( `${config.apiUrl}?res=menu`, requestOptions ).then( handleServiceResponse );
}

function getMenuList() {
  const requestOptions = {
    method: 'GET'
  };

  return fetch(`${config.apiUrl}?res=menu&sub=list`, requestOptions).then(handleServiceResponse);
}

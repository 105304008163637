import React, {Component} from 'react';
import { connect } from 'react-redux';

import {
	selectCarrier,
	selectTrust,
	selectPlanDate,
	selectNetwork
} from '../../Actions/filter.actions';

import FilterDropdown from '../../Components/FormComponents/FilterDropdown';


class PlanFilterContainer extends Component{
	constructor( props ){
		super( props );

		this.handleDropDownChange = this.handleDropDownChange.bind( this );
	}

	componentDidMount(){
		this.props.selectCarrier( 0 );
		this.props.selectTrust( 0 );
	}

	componentDidUpdate( prevProps, prevState, snapshot ){
		console.log( prevProps );
	}

	handleDropDownChange( name, val ){
		switch( name ){
			case "carrier":
				this.props.selectCarrier( val.key );
				this.setState( {stat:"new"} );
				break;

			case "trust":
				this.props.selectTrust( val.key );
				this.setState( {stat:"new"} );
				break;

			case "plandate":
				this.props.selectPlanDate( val.key );
				this.setState( {stat:"new"} );
				break;

			case "network":
				this.props.selectNetwork( val.key );
				this.setState( {stat:"new"} );
				break;

			default:
				break;

		}
	}

	render(){
		return(
			<div>
				<FilterDropdown
					name="carrier"
					label = "Carrier"
					nameField="carrier_name"
					idField = "carrier_id"
					depend = "none"
					filterObj = {{op:"carriers"}}
					callbackFunc = {this.handleDropDownChange} />

				<FilterDropdown
					name="trust"
					label = "Trust"
					nameField="trust_name"
					idField = "trust_id"
					depend = "carrier"
					filterObj = {{op:"trusts",carrier:this.props.carrier}}
					callbackFunc = {this.handleDropDownChange} />

				<FilterDropdown
					name="plandate"
					label = "Plan Effective Date"
					nameField="plan_effective_date"
					idField = "plan_effective_date"
					depend = "trust"
					filterObj = {{op:"plandates",carrier:this.props.carrier,trust:this.props.trust}}
					callbackFunc = {this.handleDropDownChange} />

				<FilterDropdown
					name="network"
					label = "Network"
					nameField="network_name"
					idField = "network_name"
					depend = "plandate"
					filterObj = {{op:"networks",carrier:this.props.carrier,trust:this.props.trust, plandate:this.props.plandate}}
					callbackFunc = {this.handleDropDownChange} />
			</div>
		);
	}
}

function mapStateToProps( state, ownProps ){
	return {
		carrier:state.filter.carrier,
		trust:state.filter.trust,
		plandate:state.filter.plandate,
		network:state.filter.network
	}
}

const mapDispatchToProps = {
	selectCarrier,
	selectTrust,
	selectPlanDate,
	selectNetwork
}

const PlanFilter = connect(
	mapStateToProps,
	mapDispatchToProps
)(PlanFilterContainer);

export default PlanFilter;
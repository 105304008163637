import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import classNames from 'classnames';

// import { history } from '../../helpers/history';
import { userActions } from '../../Actions/user.actions.js';

class AppProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      redirect: false,
      redirectRoute: ""
    };

    this.onMenuExtendClick = this.onMenuExtendClick.bind( this );
    this.onLogoutClick = this.onLogoutClick.bind( this );
    this.onRouteClick = this.onRouteClick.bind( this );
  }

  onMenuExtendClick( event ) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }

  onRouteClick( route ){
    console.log( "clicky clicky" );
    this.setState( {redirect: true, redirectRoute: route} );
  }

  onLogoutClick( event ) {
    event.preventDefault();
    console.log( "clicked logout" );
    this.props.dispatch(userActions.logout());
  }

  renderRedirect(){
    if( this.state.redirect ){
      return ( <Redirect to={this.state.redirectRoute} push={true} /> );
    }
  }

  render() {
    
    return (      
      <div className="layout-profile">
        {this.renderRedirect()}
        <div>
          <img id="profileAvatar" src="assets/layout/images/profile.png" alt="" onClick={this.onMenuExtendClick} />
        </div>
        <button className="p-link layout-profile-link" onClick={this.onMenuExtendClick}>
          <span className="username">{this.props.user.first_name} {this.props.user.last_name}</span>
          <i className="pi pi-fw pi-cog" />
        </button>
        <ul className={classNames({ 'layout-profile-expanded': this.state.expanded })}>
          <li><button className="p-link" onClick={() => this.onRouteClick( '/account' )}><i className="pi pi-fw pi-user" /><span>Account</span></button></li>
          <li><button className="p-link"><i className="pi pi-fw pi-inbox" /><span>Notifications</span><span className="menuitem-badge">2</span></button></li>
          <li><button className="p-link" onClick={this.onLogoutClick}><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li>
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const{ isLoggedIn, user } = state.authentication;
  return {
   isLoggedIn,
   user
  };
}

const connectedAppProfile = connect(mapStateToProps)(AppProfile);
export { connectedAppProfile as AppProfile };
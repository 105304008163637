import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UAParser } from 'ua-parser-js';
import { Panel } from 'primereact/panel';
import { Card } from 'primereact/card';
import { DbStats } from '../../Components/Dashboard/DbStats';
import { LoginStats } from '../../Components/Dashboard/LoginStats';
import { statsService } from '../../Service/statsservice.js';

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = { }
  }

  render() {

    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-6 p-lg-6">
          <LoginStats user={this.props.user} />
        </div>
        <div className="p-col-6 p-lg-6">
          <DbStats />
        </div>
      </div>
    );

  }
}

function mapStateToProps(state) {
  const { isLoggedIn, user } = state.authentication;
  return {
    isLoggedIn,
    user
  };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };
import React, { Component, Fragment } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toolbar } from 'primereact/toolbar';
import { PlanField } from './Components/PlanField';
import uuid from 'react-uuid';
import { managePlanService } from '../../Service/manageplans';
import { Button } from 'primereact/button';
import { ToggleButton } from 'primereact/togglebutton';

export class PlanEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      delta: {},
      disabled: true,
      generalMap: this.props.generalMap,
      planIsLoaded: false
    }

    this.getPlanData = this.getPlanData.bind( this );

    this.getOptionList = this.getOptionList.bind( this );
    this.getPlanValue = this.getPlanValue.bind( this );
    this.renderPlanCategory = this.renderPlanCategory.bind( this );
    
    this.handleValueChange = this.handleValueChange.bind( this );

    this.updatePlanPublish = this.updatePlanPublish.bind( this );
    this.updatePlanDemo = this.updatePlanDemo.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind( this );

  }

  componentDidMount(){
    if( this.props.planId ) {
      this.getPlanData();
    }
  }

  getPlanData(){
    managePlanService.getPlans( this.props.planId )
    .then( response => {
      console.log( response.data[0] );
      this.setState( {
        data: response.data[0], 
        planIsLoaded: true
      } );
    } )
    .catch( error => {
      console.log( error );
    } );
  }

  handleSaveClick(){
    this.props.onSave( this.state.delta );
  }

  handleValueChange( payload ){
    console.log( "payload" );
    console.log( payload );
    let data = this.state.data;
    let delta = this.state.delta;

    if( !delta.plan_id ){
      delta.plan_id = data.plan_id;
      delta.terms = [];
    }

    const field = payload.field;
    const value = payload.value;

    if( field.table ){
      data[field.valueField] = value;
      delta[field.valueField] = data[field.valueField];
    }
    else {
      console.log( "looking through terms" );
      const terms = data.terms;
      let local = null;
      let dt = null;
      
      // update local data
      for (let x = 0; x < terms.length; ++x) {
        if (terms[x].name === field.label) {
          terms[x].term = value
          local = terms[x];
          console.log(terms[x] );
        }
      }

      if( local === null ){
        local = {category_name: field.categoryName, name: field.label, term: value};
        terms.push( local );
      }

      // update change delta
      for (let x=0; x < delta.terms.length; ++x ){
        if( delta.terms[x] && delta.terms[x].name === field.label ){
          dt = x;
        }
      }

      if( dt === null ) {
        if( local !== null ) delta.terms.push( local ); // already added this one so change it
      }

      else delta.terms[dt].term = value; // new value
    }
    
    this.setState( {data: data, delta: delta} );
    console.log( this.state.delta );
  }

  updatePlanPublish( value ){
    const field = {
      table:"medical_library_plans",
      valueField: "published"
    }
    const payload = {
      value: (value === true ? "1" : "0"),
      field: field
    }

    this.handleValueChange( payload );
  }

  updatePlanDemo( value ){
    let data = this.state.data;
    data.demo_visible = (value === true ? "1" : "0");

    const field = {
      table: "medical_library_plans",
      valueField: "demo_visible"
    }
    const payload = {
      value: (value === true ? "1" : "0"),
      field: field
    }

    this.handleValueChange(payload);
  }

  getOptionList( field ){
    if( field.table ) return [];
    
    // find the category and field were looking for
    const categories = this.props.categoryMap;
    for( let x=0; x<categories.length; x++ ){
      const fields = categories[x].fields;
      for( let i=0; i<fields.length; i++ ){
        if( fields[i].name === field.label )
          return fields[i].terms;
      }
    }

    return [];
  }

  getPlanValue( field ){
    if( field.table ){
      return this.state.data[field.valueField];
    }
    else{
      const terms = this.state.data.terms;
      for( let x=0; x < terms.length; ++x ){
        if( terms[x].name === field.label ){
          return terms[x].term;
        }
      }
    }
  }

  renderPlanCategory( category ){    
    return (
      <AccordionTab header={category.title} key={uuid()}>
        <div className="p-grid p-fluid" key={uuid()}>
          {
            category.fields.map( (field) => (
              <div className="p-col-6" key={uuid()}>
                <PlanField 
                  field={field}
                  id={field.name} 
                  value={this.getPlanValue( field )}
                  tableName={field.table} 
                  labelField={field.labelField}
                  optionList={this.getOptionList( field )}
                  dataListName={uuid()}
                  placeholder="Select or Enter a Term"
                  onChange={this.handleValueChange}
                  disabled={this.state.disabled} />
              </div>
            ) )
          }
        </div>
      </AccordionTab>
    );
  }

  render() {
    if( this.state.planIsLoaded ){
      
      const demo = (this.state.data.demo_visible === "1" ? true : false);
      const published = (this.state.data.published === "1" ? true : false);

      const tbLeft = (
        <Fragment>
          <ToggleButton 
            onLabel="Published" offLabel="Not Published"
            onIcon="pi pi-check" offIcon="pi pi-times"
            style={{marginRight:"5px"}} 
            checked={published} 
            onChange={(e) => this.updatePlanPublish( e.value )} />
          <ToggleButton 
            onLabel="Demo" offLabel="Demo" 
            onIcon="pi pi-check" offIcon="pi pi-times"
            checked={demo} 
            onChange={(e) => this.updatePlanDemo( e.value )} />
        </Fragment>
      );

      const tbRight = (
        <Fragment>
          <ToggleButton 
            onLabel="Edit Locked" offLabel="Edit On" 
            style={{ marginRight: '5px' }} 
            checked={this.state.disabled} 
            onChange={(e) => this.setState( {disabled:e.value} )} />
          <Button label="Save Changes" className="p-button-success" disabled={this.state.isDisabled} onClick={this.handleSaveClick} />
        </Fragment>
      );

      return (
        <div>
          <Toolbar left={tbLeft} right={tbRight} />
          <div className="p-grid p-fluid">
            <Accordion multiple={true} className="p-col-12">
              {this.props.generalMap.map( ( cat ) => this.renderPlanCategory( cat ))}
            </Accordion>
          </div>
        </div>
      );
    }
    else{
      return (<div>Loading...</div>);
    }
  }
}
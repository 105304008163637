import React, { Component } from 'react';
import { LookupEdit } from '../../../Components/FormComponents/LookupEdit/LookupEdit';

export default class NetworkManager extends Component {

  render() {
    let networkColumns = [
      { field: "network_id", header: "ID", ignore: true, width: '50px' },
      { field: "network_name", header: "Network Name", sortable: true, type: "string", width: '45%' },
      { field: "network_name_search_url", header: "Network Name URL", sortable: true, type: "string", width: '45%' }
    ]

    return (
      <div className="p-grid">
        <div className="p-col-12">
          <LookupEdit header="Networks" idField="network_id" tblName="medical_library_networks" columns={networkColumns} />
        </div>
      </div>
    );
  }

}
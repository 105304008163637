import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { filterService } from '../../Service/filter';
import { PlanDialog } from "./plandialog.js";
import { managePlanService } from "../../Service/manageplans.js";
import { PlanEditor } from "../PlanEditor/planeditor.js";
import { Toast } from 'primereact/toast';

class PlanListContainer extends Component{
	constructor( props ){
		super( props );

		this.state ={
      isLoaded: false,
      refresh: false,
      isFetching: false,
      plans: null,
      map:null,
      general: null,
      saveMode: null,
			inEditMode: false
		}

    this.dialog = React.createRef();

		this.getPlans = this.getPlans.bind( this );
		this.rowExpansionTemplate = this.rowExpansionTemplate.bind( this );
		this.handleEditClick = this.handleEditClick.bind( this );
		this.handleSave = this.handleSave.bind( this );
		this.handleSelectionChange = this.handleSelectionChange.bind( this );

		this.handleCloneClick = this.handleCloneClick.bind( this );
		this.handleBatchEditClick = this.handleBatchEditClick.bind( this );

    this.savePlans = this.savePlans.bind( this );
    this.handleDialogSave = this.handleDialogSave.bind( this );
	}

	static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
//-----------------------------------------------------------------------------
  componentDidMount(){
  	if( this.props.filterObj ){
  		console.log( this.props.filterObj );
			this.getPlans();
		}
  }
//-----------------------------------------------------------------------------
	componentDidUpdate( prevProps, prevState, snapshot ){
		if( prevProps.filterObj !== this.props.filterObj ){
			console.log( "planlist: detected state update" );
			this.getPlans();
    }
    else if( prevState.refresh !== this.state.refresh && prevState.refresh === true ){
      this.getPlans();
    }
	}
//-----------------------------------------------------------------------------
	getPlans(){
		if( this.props.filterObj.network ){
			let tmpFilter = this.props.filterObj;
      tmpFilter.op = "plans";
      
      this.setState( {isFetching: true} );

      fetch( process.env.PUBLIC_URL + '/data/planModel.json' )
        .then( response => response.json() )
        .then( data => this.setState({ general:data } ) );

			filterService.getFilterData( this.props.filterObj )
			.then( response => {
        this.setState( {plans:response.data} );
        
        filterService.getTerms()
        .then( response => {
          this.setState( {map:response.data, isLoaded: true, isFetching: false, refresh: false} );
        } )
        .catch( error => {
          console.log( error );
          this.setState({ isFetching: false });
        } );
			} )
			.catch( error => {
        console.log( error );
        this.setState({ isFetching: false });
			} );
		}
	}
//-----------------------------------------------------------------------------
	getClonedPlans( aIds ){
		managePlanService.getPlans( aIds )
		.then( response => {
			this.setState( {plans:response.data} );
		} )
		.catch( error => { console.log( error ) } );

	}
//-----------------------------------------------------------------------------
	handleEditClick( data, label ){
		if( label === "Cancel" && this.state.inEditMode ){
			this.setState( {planEditId: 0, inEditMode: false} );
			this.plan = null;
			this.delta = {};
			this.terms = {};
		}

		else if( label === "Edit" && !this.state.inEditMode ){
			this.setState( {planEditId: data['plan_id'], inEditMode: true, data: data} );
			this.plan = data;
		}
	}
//-----------------------------------------------------------------------------
	handleSave( planData ){
    const ids = [planData.plan_id];

    managePlanService.savePlans( ids, planData )
    .then( response => {
      console.log( response.data );
      this.setState( {planEditId: 0, inEditMode: false} );
      
      if( response.data.code === 200 ){
        this.toast.show( {severity: "success",summary:"Plan Updated", detail:"Plan successfully updated", life: 5000} );
        this.setState( {refresh: true} );
      }
    } )
    .catch( error => {
      this.toast.show({ severity: "error", summary: "Plan Update Failed", detail: error, life: 5000 });

      console.log( error )
    } );
	}
//-----------------------------------------------------------------------------
  handleDialogSave( planData ){
    let ids = this.state.selectedPlans.map(plan => {
      return plan.plan_id;
    });

    console.log( ids );
    console.log( planData );

    if( this.state.saveMode === "clone"){
      managePlanService.clonePlans( ids, planData )
      .then(response => {
        console.log(response.data);
        this.setState({ planEditId: 0, inEditMode: false, selectedPlans: null });
        this.getClonedPlans(response.data);

        if (response.data.code === 200) {
          this.toast.show({ severity: "success", summary: "Clone Plans", detail: "Plan cloned successfully", life: 5000 });          
        }
      })
      .catch(error => {
        this.toast.show({ severity: "error", summary: "Clone Failed", detail: error, life: 5000 });

        console.log(error)
      });
    }
    else if( this.state.saveMode === "batch" ){
      managePlanService.savePlans( ids, planData )
        .then(response => {
          console.log(response.data);
          this.setState({ planEditId: 0, inEditMode: false });

          if (response.data.code === 200) {
            this.toast.show({ severity: "success", summary: "Batch Plan Update", detail: "Plans successfully updated", life: 5000 });
            this.setState({ refresh: true });
          }
        })
        .catch(error => {
          this.toast.show({ severity: "error", summary: "Batch Plan Update Failed", detail: error, life: 5000 });

          console.log(error)
        });
    }

    // we can do a batch save here
    
  }
//-----------------------------------------------------------------------------
	handleSelectionChange( e ){
		console.log( e.value );
		this.setState( {selectedPlans: e.value} );
	}
//-----------------------------------------------------------------------------
	handleCloneClick( label ){
		this.setState( {saveMode:"clone"} );

		if( this.state.selectedPlans ){
			// pop dialog with first selected plan
			this.dialog.current.showDialog( this.state.selectedPlans[0], "Clone Selected Plans" );

			// console.log( JSON.stringify( this.state.selectedPlans ) );
		}
	}
//-----------------------------------------------------------------------------
	handleBatchEditClick( label ){
		this.setState( {saveMode:"batch"} );

		if( this.state.selectedPlans ){
			// pop dialog with first selected plan
			this.dialog.current.showDialog( this.state.selectedPlans[0], "Edit Selected Plans" );

			// console.log( JSON.stringify( this.state.selectedPlans ) );
		}
	}
//-----------------------------------------------------------------------------
	savePlans( delta, terms ){
		let ids = this.state.selectedPlans.map( plan => {
			return plan.plan_id;
		} );

		console.log( JSON.stringify( ids ) );
		console.log( delta );
		console.log( this.state.saveMode );

		if( this.state.saveMode === "clone" ){
			managePlanService.clonePlans( ids, delta, terms )
				.then( response => {
					console.log( response.data );
					this.setState({selectedPlans:null});
					this.getClonedPlans( response.data );
				} )
				.catch( error => {
					console.log( error )
				} );
		}
		else if( this.state.saveMode === "edit" ){
			managePlanService.savePlans( ids, delta, terms )
				.then( response => {
					console.log( response.data );
					this.setState({selectedPlans:null});
					this.getClonedPlans( response.data );
				} )
				.catch( error => {
					console.log( error )
				} );
		}
	}
//-----------------------------------------------------------------------------
	rowExpansionTemplate( data ){
		let isDisabled = true;
		let isEditDisabled =false;
		let editLabel = "Edit";
		let selectedValue = {};

		if( this.state.inEditMode && data['plan_id'] === this.state.planEditId ){
			isDisabled = false;
			editLabel = "Cancel";
			data = this.state.data;
		}

		if( this.state.inEditMode && data['plan_id'] !== this.state.planEditId )
			isEditDisabled = true;

		return(
			<div>
				<div style={{marginTop: '20px'}}>
          <PlanEditor 
            planId={data.plan_id} 
            categoryMap={this.state.map} 
            generalMap={this.state.general} 
            batchEdit={false} 
            disabled={isDisabled} 
            onSave={this.handleSave} />
				</div>
			</div>
		);

	}
//-----------------------------------------------------------------------------
	render(){
		if( this.state.plans && Array.isArray( this.state.plans ) && this.state.isLoaded ){

			var header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                    Filtered Plan List
                    <Button icon="pi pi-refresh" className="p-button-secondary" style={{'float':'right', 'marginLeft':'5px'}} onClick={this.getPlans} />
                    <Button label="Clone Selected" 
                      icon="pi pi-clone" 
                      className="p-button-secondary"  
                      style={{'float':'right'}} 
                      onClick={this.handleCloneClick} 
                      disabled={(!this.state.isLoaded)} />

                    <Button 
                      label="Batch Edit Selected" 
                      icon="pi pi-pencil" 
                      className="p-button-secondary"  
                      style={{'float':'right', marginRight:"5px"}} 
                      onClick={this.handleBatchEditClick} />
									 </div>;
			let planCount = (this.state.plans.length!==undefined)?this.state.plans.length:0;
      var footer = "There are " + planCount + ' plans matching the filter criteria';

			return(
				<div>
          <Toast ref={(el) => this.toast = el} />
          <PlanDialog 
            ref={this.dialog} 
            plans={this.state.selectedPlans} 
            categoryMap={this.state.map} 
            generalMap={this.state.general} 
            onSave={this.handleDialogSave} />
					
          <DataTable
						value={this.state.plans}
						header={header}
						footer={footer}
						expandedRows={this.state.expandedRows}
						onRowToggle={(e) => this.setState({expandedRows:e.data})}
	          selection={this.state.selectedPlans}
	          onSelectionChange={this.handleSelectionChange}
	          rowExpansionTemplate={this.rowExpansionTemplate}>

	          <Column expander={true} style={{width: '3em'}} />
			      <Column selectionMode="multiple" style={{width:'3em'}} />
	          <Column field="plan_id" header="Database ID" />
						<Column field="carrier_name" header="Carrier" />
						<Column field="trust_name" header="Trust" />
						<Column field="plan_type_name" header="Plan Type" />
						<Column field="carrier_specific_code" header="Code" />

		      </DataTable>
	      </div>
			);
		}
		else if( this.state.plans && !Array.isArray( this.state.plans ) ){
			return ( <div>No plans found matching the selected criteria.</div> );
    }
    else if( this.state.isFetching )
      return (<div><ProgressSpinner /></div>);
		else{
      return (<div></div>);
		}
	}
}

function mapStateToProps( state, ownProps ){
	return {
		filterObj:state.filter
	}
}

/*const mapDispatchToProps = {
	selectCarrier,
	selectTrust,
	selectPlanDate,
	selectNetwork
}*/

const PlanList = connect(
	mapStateToProps
)(PlanListContainer);

export default PlanList;
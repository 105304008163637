import { userConstants } from '../Constants/user.constants';
import { userService } from '../Service/UserService';
import { alertActions } from './alert.actions';
import { history } from '../Helpers/history';

export const userActions = {
	login,
	logout,
	getAll
};

function login( username, password ){
	return dispatch => {
		dispatch( request( {username} ) );

		userService.login( username, password )
			.then(
				user => {
					dispatch( success( user ) );
					history.push( '/' );
				},
				error => {
					dispatch( failure( error ) );
					dispatch( alertActions.error( error ) );
				}
			);
	};

	function request( user ) { return { type: userConstants.LOGIN_REQUEST, user } }
	function success( user ) { return { type: userConstants.LOGIN_SUCCESS, user } }
	function failure( error ) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout(){
	userService.logout();
	return { type:userConstants.LOGOUT };
}

function getAll(){
	return dispatch => {
		dispatch( request() );

		userService.getAll()
			.then(
				data => dispatch( success( data ) ),
				error => {
					dispatch( failure( error ) );
					dispatch( alertActions.error( error ) )
				}
		);
  }
  
  // auth action creators
  function request() { return { type:userConstants.GETALL_REQUEST } }
  function success( data ) { return { type:userConstants.GETALL_SUCCESS, data } }
  function failure( error ) { return { type:userConstants.GETALL_FAILURE, error } }
}
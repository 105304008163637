import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { BooleanDropdown } from '../../Components/FormComponents/BooleanDropdown';

export class ResourceEditor extends React.Component {

  constructor( props ) {
    super( props );

    this.state = {
      res: null
    }

    this.updateProperty = this.updateProperty.bind( this );
  }

  updateProperty( name, val ){

  }

  render(){
    let panelHeader = "Resource Details";

    if( this.props.resource ){
      return (
        <div>
          <Panel header={panelHeader} toggleable={false}>
            <div className="p-grid p-fluid">
              
              <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="name">Menu Name</label></div>
              <div className="p-col-8" style={{ padding: '.5em' }}>
                <InputText id="name" onChange={(e) => { this.updateProperty('resource_name', e.target.value) }} value={this.props.resource.resource_name} />
              </div>

              <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="path">Resource Path</label></div>
              <div className="p-col-8" style={{ padding: '.5em' }}>
                <InputText id="path" onChange={(e) => { this.updateProperty('resource_url_path', e.target.value) }} value={this.props.resource.resource_url_path} />
              </div>

              <Accordion>
                <AccordionTab header="Menu Settings">
                  <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="submenu">Submenu Of</label></div>
                  <div className="p-col-8" style={{ padding: '.5em' }}>
                    <Dropdown id="submenu"
                      optionLabel="resource_name"
                      optionValue="resource_id"
                      options={this.state.menu}
                      onChange={(e) => { this.updateProperty('resource_parent_id', e.target.value) }}
                      value={this.props.resource.resource_parent_id} />
                  </div>

                  <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="categoryOnly">Category Only</label></div>
                  <div className="p-col-8" style={{ padding: '.5em' }}>
                    <BooleanDropdown id="categoryOnly" onChange={(e) => { this.updateProperty('resource_category_only', e.target.value) }} selected={this.props.resource.resource_category_only} />
                  </div>

                  <div className="p-col-4" style={{ padding: '.75em' }}><label htmlFor="showInMenu">Show In Menu</label></div>
                  <div className="p-col-8" style={{ padding: '.5em' }}>
                    <BooleanDropdown id="showInMenu" onChange={(e) => { this.updateProperty('resource_show_in_menu', e.target.value) }} selected={this.props.resource.resource_show_in_menu} />
                  </div>
                </AccordionTab>
                <AccordionTab header="RBAC Settings">

                </AccordionTab>
              </Accordion>

            </div>
            <div className="ui-dialog-buttonpane p-clearfix">
              <Button label="Save" icon="pi pi-check" onClick={this.onSave} className="p-align-right" />
              <Button label="Cancel" icon="pi pi-times" onClick={this.props.onCloseInspector} className="p-button-secondary" />              
            </div>
          </Panel>
        </div>
      );
    }
    else {
      console.log( "nope" );
      return( <div></div> );
    }
  }
}
import React, { Component } from 'react';
import { LookupEdit } from '../../../Components/FormComponents/LookupEdit/LookupEdit';

export default class TrustManager extends Component {

  render() {
    let trustColumns = [
      { field: "trust_id", header: "ID", ignore: true, width: '50px' },
      { field: "trust_name", header: "Name", sortable: true, type: "string", width: '150px' },
      { field: "demo_visible", header: "Demo Visible", type: "boolean", width: '100px' }
    ]

    return (
      <div className="p-grid">
        <div className="p-col-12">
          <LookupEdit header="Trusts" idField="trust_id" tblName="medical_library_trusts" columns={trustColumns} />
        </div>
      </div>
    );
  }

}
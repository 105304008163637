import { config as Config } from '../Config/config.js';

const createMCCService = () => {

  const getMCCEntries = async ( options, filters ) => {
    const query = new URLSearchParams(  {
      res: "mcc",
    } );

    if( options ) {
      if( (options.page !== undefined && options.page !== null) && (options.count !== undefined && options.count !== null) ) {
        query.append( "page", options.page );
        query.append( "count", options.count );
      }

      if( options.sortField && options.sortOrder ) {
        query.append( "sort", options.sortField );
        query.append( "order", options.sortOrder );
      }

      if( options.filterField && options.filteValue ){
        query.append( "filter", options.filterField );
        query.append( "filterValue", options.filterValue );
      }
    }

    console.log( filters );
    if( filters ){
      console.log( `we should apply filters here` );
      query.append( "filters", JSON.stringify( filters ) );
    }

    let strUrl = `${Config.apiUrl}?${query.toString()}`;
    console.log( strUrl );
    const resp = await fetch( strUrl, {method: "GET"} );
    const json = await resp.json();

    return json;

  }

  const getMccCount = async () => {

    const query = new URLSearchParams(  {
      res: "mcc",
      sub: "count"
    } );

    let strUrl = `${Config.apiUrl}?${query.toString()}`;
    console.log( strUrl );

    const resp = await fetch( strUrl, {method: "GET"} );
    const json = await resp.json();

    return json;
  }

  const addMccEntry = async ( entry ) => {
    const query = new URLSearchParams(  {
      res: "mcc",
    } );

    const options = {
      method: "POST",
      body: JSON.stringify( entry )
    }

    let strUrl = `${Config.apiUrl}?${query.toString()}`;
    console.log( strUrl );

    const resp = await fetch( strUrl, options );
    const json = resp.json();

    return json;
  }

  const updateMccEntry = async ( id, entry ) => {
    const query = new URLSearchParams(  {
      res: "mcc",
      id: id
    } );
    const options = { 
      method: "POST",
      body: JSON.stringify( entry )
    }

    let strUrl = `${Config.apiUrl}?${query.toString()}`;
    console.log( strUrl );

    const resp = await fetch( strUrl, options );
    const json = resp.json();

    return json;
  }

  return {
    getMCCEntries,
    getMccCount,
    addMccEntry,
    updateMccEntry
  }
}

export const mccService = createMCCService();
import React, { Component } from 'react';
import { LookupEdit } from '../../../Components/FormComponents/LookupEdit/LookupEdit';
import { MccEntryTable } from './MccEntryTable';

export default class PlanTypeManager extends Component {

  render() {
    let termNameColumns = [
      { field: "mcc_name_id", header: "ID", ignore: true, width: '50px' },
      { field: "name", header: "Name", sortable: true, type: "string", width: '150px' }
    ]

    let termColumns = [
      { field: "mcc_term_id", header: "ID", ignore: true, width: '50px' },
      { field: "term", header: "Term", sortable: true, type: "string", width: '150px' },
      { field: "abbrv", header: "Abbr", sortable: true, type: "string", width: '50px' }
    ]

    let termCatColumns = [
      { field: "mcc_category_id", header: "ID", ignore: true, width: '50px' },
      { field: "category_name", header: "Category", sortable: true, type: "string", width: '150px' }
    ]

    return (
      <div className="p-grid">
        <div className="p-col-12">
          <MccEntryTable />
        </div>
        {/* <div className="p-col-12">
          <LookupEdit header="Plan Categories" idField="mcc_category_id" tblName="medical_care_coverage_categories" columns={termCatColumns} />
        </div>
        <div className="p-col-6">
          <LookupEdit header="Plan Term Names" idField="mcc_name_id" tblName="medical_care_coverage_names" columns={termNameColumns} />
        </div>
        <div className="p-col-6">
          <LookupEdit header="Plan Terms" idField="mcc_term_id" tblName="medical_care_coverage_terms" columns={termColumns} />
        </div> */}
      </div>
    );
  }

}
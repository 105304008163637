import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';

export class BooleanDropdown extends Component{
	constructor( props ){
		super( props );

		this.state = {
			selected: this.props.value
		}

		this.handleChange = this.handleChange.bind( this );
	}

	componentDidUpdate( prevProps, prevState ){
		// we only wanna watch for the data to change in the state
		if( prevState.selected !== this.props.selected )
			this.setState( {selected:this.props.selected} );
	}

	handleChange( e ){
		this.setState( {selected: e.value} );
		this.props.onChange( e.value.value );
	}

	render(){
		var options = [
			{	label: "Yes", value: "1" },
			{	label: "No", value: "0" }
		];

		return(
			<Dropdown
				key={this.props.name}
				optionLabel="label"
				options={options}
				value={this.state.selected}
				placeholder={this.props.label}
				disabled={this.props.disabled}
        onChange={this.handleChange}
        onBlur={this.props.onBlur}
				/>
		);
	}
}
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Toast } from 'primereact/toast';
import { userActions } from '../../Actions/user.actions.js';

import './Login.scss';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState( { submitted: true } );
    const { username, password } = this.state;
    const { dispatch } = this.props;

    if( username && password ) {
      dispatch( userActions.login( username, password ) );
    }
  }

  render() {
    // const { username } = this.state;

    if( this.props.isLoggedIn ){
      return <Redirect to="/" />
    }

    return (
      <div className="p-grid p-align-center p-justify-center vertical-container" style={{ marginTop: "100px" }}>
        <Toast ref={(el) => this.toast = el} />
        <div className="p-col-12 p-md-6 p-lg-4 p-grid">
          <div className="p-col-6" style={{ textAlign: "left" }}>
            <h2 style={{ margin: 0, fontSize: "2.0em" }}>Login</h2>
          </div>
          <div className="p-col-6" style={{ textAlign: "right" }}>
            <div style={{ fontSize: "1.2em" }}>Welcome Guest</div>
            <div style={{ fontSize: "0.85em" }}>Sign in to The Benefits Academy</div>
          </div>

          <div className="p-col-12" style={{ textAlign: "left" }}>
            Username or Email<br></br>
            <InputText name="username" style={{ width: "100%" }} onChange={this.handleChange} />
          </div>
          <div className="p-col-12" style={{ textAlign: "left" }}>
            Password<br></br>
            <Password
              name="password"
              feedback={false}
              style={{ width: "100%" }}
              onChange={this.handleChange}
              onKeyPress={ (e)=>{if( e.key === 'Enter' ) this.handleSubmit( e ) } } />
          </div>
          <div className="p-col-6" style={{ textAlign: "left" }}>
            <Button type="submit" label="Sign In" style={{ width: "80%", marginTop: "10px" }} onClick={this.handleSubmit} />
          </div>
          <div className="p-col-6" style={{ textAlign: "right" }}>
            <a href="/" style={{ display: "block", marginTop: "20px" }}>Forgot Password</a>
          </div>
        </div>

      </div>
    );
  }
}

function mapStateToProps( state ) {
  console.log( state );
  const { loggingIn, isLoggedIn } = state.authentication;
  return {
    loggingIn,
    isLoggedIn
  };
}

const connectedLoginPage = connect(mapStateToProps)(Login);
export { connectedLoginPage as Login };
import React, {Component, Fragment} from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import PlanFilter from '../PlanFilter/planfilter';
import PlanList from '../PlanList/planlist';

export default class PlanManager extends Component{
	render(){
    const leftContents = ( <PlanFilter /> );
    const rightContents = ( <Button label="Add New Plan" className="p-primary" onClick={() => alert("not implemented")} /> );
    
		return(
			<div id="main-container">
        <Toolbar left={leftContents} right={rightContents} />
				<div>
					<PlanList/>
				</div>
			</div>
		);
	}
};

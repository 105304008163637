import React, { Component } from 'react';
import ResourceTable from './ResourceTable';
import { ResourceEditor } from './ResourceEditor';

export default class ResourceManager extends Component {
  constructor( props ){
    super( props );

    this.state = {
      selectedRes: null
    }

    this.handleSelectedResource = this.handleSelectedResource.bind( this );
    this.handleInspectorClosed = this.handleInspectorClosed.bind( this );
  }

  handleSelectedResource( res ){
    this.setState( { selectedRes: res } );
  }

  handleInspectorClosed(){
    this.setState( { selectedRes: null } );
  }

  render() {
    let mainDivClass = "p-col-12";
    let inspectorDivClass = "";
    let visibility = "hidden";

    if( this.state.selectedRes !== null ){
      mainDivClass = "p-col-8";
      inspectorDivClass = "p-col-4";
      visibility = "visible"
    }

    return (
      <div id="main-container" className="p-grid">
        <div className={mainDivClass}>
          <ResourceTable onSelectedResource={this.handleSelectedResource}/>
        </div>
        <div className={inspectorDivClass} style={ {visibility:visibility} } >
          <ResourceEditor resource={this.state.selectedRes} onCloseInspector={this.handleInspectorClosed} />
        </div>
      </div>

    );
  }
};
import { userService } from './UserService';

export function handleServiceResponse( response ) {
  return response.text().then( text => {
    console.log( text );
    const data = text && JSON.parse( text );
    if( !response.ok ) {
      if( response.status === 401 ){
        // auto logout if 401 response returned from api
        userService.logout();
        // window.location.reload( true );
      }

      const error = ( data && data.message ) || response.statusText;
      return Promise.reject( error );
    }

    return data;
  } );
}
import React, { Component } from 'react';
import UserTable from './UserTable';
import { UserToolbar } from './UserToolbar';
import { UserEditor } from './UserEditor';
import { userService } from '../../Service/UserService';

export default class UserManager extends Component {
  
  constructor(props){
    super( props );

    this.state = {
      showInspector: false,
      selectedUser: null
    }

    this.handleSelUser = this.handleSelUser.bind( this );
    this.handleAddUserClick = this.handleAddUserClick.bind( this );
    this.handleInspectorClosed = this.handleInspectorClosed.bind( this );
    this.handleSaveUserClick = this.handleSaveUserClick.bind( this );
  }

  handleSelUser( user ){
    this.setState( {selectedUser: user, showInspector: true} );
  }

  handleAddUserClick(){
    this.setState({ selectedUser: null, showInspector: true } );
  }

  handleSaveUserClick( user ){
    if( !this.state.selectedUser ){
      console.log( "we will add the new user" );
      console.log( user );
      userService.addNewUser( user );
    }
    else{
      console.log( "we will update the user" );
      console.log( user );
      userService.updateUser( user );
    }
  }

  handleInspectorClosed(){
    this.setState( {showInspector: false} );
  }
  
  render() {
    let mainDivClass = "p-col-12";
    let inspectorDivClass = "";
    let visibility = "hidden";

    if (this.state.showInspector ) {
      mainDivClass = "p-col-8";
      inspectorDivClass = "p-col-4";
      visibility = "visible"
    }

    return (
      <div id="main-container">
        <UserToolbar onAddUser={this.handleAddUserClick} />
        <div className="p-grid">
          <div className={mainDivClass}>
            <UserTable onSelectUser={this.handleSelUser} />
          </div>
          <div className={inspectorDivClass} style={{ visibility: visibility }} >
            <UserEditor user={this.state.selectedUser} onSaveUser={this.handleSaveUserClick} onCloseInspector={this.handleInspectorClosed} defaultRole="5" defaultStatus="3" />
          </div>
        </div>
      </div>
    );
  }
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { FileUpload } from 'primereact/fileupload';


class Account extends Component {

  constructor() {
    super();

    this.state = {
      password: "",
      newPass: "",
      confirmMsg: "",
      confirmColor: "green"
    }

    this.changePassword = this.changePassword.bind( this ); 
    this.confirmPassword = this.confirmPassword.bind( this );
    this.changeUserValue = this.changeUserValue.bind( this );
    this.savePassword = this.savePassword.bind( this );
  }

  componentDidMount(){
    // get profile 
    this.setState( {user: this.props.user} );
  }

  saveProfile(){
    // patch profile
  }

  savePassword(){
    let pass = this.state.passwd;

    // patch password {user_id,password,newPass}
  }

  changeUserValue( name, value ){
    let user = this.state.user;

    user[name] = value;
    this.setState( {user: user} );
  }

  changePassword( passwd ){
    this.setState( {newPass: passwd, safeToSave:false} );
  }

  confirmPassword( confText ){
    if( confText !== this.state.newPass )
      this.setState( {confirmMsg:"Passwords do not match", confirmColor:"red", safeToSave:false} );
  
    else
      this.setState({ confirmMsg: "Passwords match", confirmColor: "green",safeToSave:true });
  }

  render() {
    console.log( this.props );

    let title = "Account Settigns";
    if( this.props.title ) title = this.props.title;

    return ( 
      <div>
        <div className="p-grid">
          <div className="p-col-6"><h1>{title}</h1></div>
          <div className="p-col-6">
            <div style={{textAlign:"right"}}>
              <Button label="Save" className="p-button-success" icon="pi pi-check" />
            </div>
          </div>
        </div>
        <hr />
        <div className="p-grid p-align-center">
          <div className="p-col-2" style={{textAlign:"center"}}>
            <img id="profileAvatar" src="assets/layout/images/profile.png" alt="" width="128" style={{borderRadius:"64px", border:"2px solid #a0a0a0"}} />
          </div>
          <div className="p-col-2">
            <div><h3 style={{marginTop:"0px", marginBottom:"5px"}}>Change Avatar</h3></div>
            <div>
              multi-line<br />
              blurb<br />
            </div>
          </div>
          <div className="p-col-2">
            <FileUpload mode="basic" label="Upload" />
          </div>
        </div>
        <hr />
        <div className="p-grid">
          <div className="p-col-3" style={{ fontSize: "16px" }}>First Name<br />
            <InputText id="first_name" style={{ width: "260px" }} value={this.props.user.first_name} onChange={(e) => this.changeUserValue("first_name", e.target.value)} />
          </div>
          
          <div className="p-col-9" style={{ fontSize: "16px" }}>Last Name<br />
            <InputText id="last_name" style={{ width: "260px" }} value={this.props.user.last_name} onChange={(e) => this.changeUserValue("last_name", e.target.value)} />
          </div>
          
          <div className="p-col-3" style={{ fontSize: "16px" }}>Username<br />
            <InputText id="username" style={{ width: "260px" }} value={this.props.user.username} onChange={(e) => this.changeUserValue("username", e.target.value)} />
          </div>
          
          <div className="p-col-9" style={{ fontSize: "16px" }}>Email Address<br />
            <InputText id="email" style={{ width: "260px" }} value={this.props.user.email} onChange={(e) => this.changeUserValue("email", e.target.value)} />
          </div>
        </div>
        <hr />
        <h2 style={{marginBottom:"10px"}}>Change Password</h2>
        <div className="p-grid">
          <div className="p-col-3" style={{ fontSize: "16px" }}>Old Password<br /><Password id="password" feedback={false} style={{ width: "260px" }} /></div>
          <div className="p-col-3" style={{ fontSize: "16px" }}>New Password<br />
            <Password id="newpw" style={{ width: "260px" }} onChange={(e) => this.changePassword( e.target.value ) } />
          </div>
          <div className="p-col-3" style={{ fontSize: "16px" }}>Confirm<br />
            <Password id="confirm" feedback={false} style={{ width: "260px" }} onChange={(e) => this.confirmPassword( e.target.value )} /><br />
            <div id="confirmFeedback" style={{color:this.state.confirmColor, marginTop:"5px"}}>{this.state.confirmMsg}</div>
          </div>
          <div className="p-col-3" style={{ fontSize: "16px" }}><br /><Button label="Change" className="p-button-primary" onClick={this.savePassword} /></div>
        </div>
      </div> 
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

const connectedAccount = connect(mapStateToProps)(Account);
export { connectedAccount as Account };
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { BooleanDropdown } from '../../Components/FormComponents/BooleanDropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { menuService } from '../../Service/MenuService';
import { Dropdown } from 'primereact/dropdown';

export default class ResourceTable extends Component {
  constructor(props) {
    super(props);

    console.log( props );

    this.state = {
      isLoaded: false
    };

    this.onMenuSelect = this.onMenuSelect.bind( this );
    this.updateProperty = this.updateProperty.bind( this );
  }

  componentDidMount() {
    this.getMenuItems();
  }

  getMenuItems() {
    menuService.getMenuList()
      .then(response => {
        console.log(response);
        let menuList = response.list;

        for( let x=0; x < menuList.length; ++x ){
          menuList[x].resource_url_path = (menuList[x].resource_url_path == null ? "" : menuList[x].resource_url_path );
          menuList[x].resource_show_in_menu_text = menuList[x].resource_show_in_menu == 1?"Yes":"No";
        }

        this.setState({ menu: menuList, isLoaded: true });
      })
      .catch(error => { console.log(error) });
  }

  updateProperty( property, value ) {
    let selMenu = this.state.selMenu;
    selMenu[property] = value;
    this.setState({ selMenu: selMenu } );
  }

  onMenuSelect( e ){
    this.props.onSelectedResource( e.data );
  }

  render() {
    let header = <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>System Resource Items </div>;

    let footer = <div className="p-clearfix" style={{ width: '100%' }}>
      <Button style={{ float: 'left' }} label="Add" icon="pi pi-plus" onClick={this.addNew} />
    </div>;

    let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
      <Button label="Delete" icon="pi pi-times" onClick={this.onDelete} />
      <Button label="Save" icon="pi pi-check" onClick={this.onSave} />
    </div>;

    if (this.state.isLoaded) {
      return (
        <div>
          <DataTable
            value={this.state.menu}
            autoLayout={true}
            selectionMode="single"
            onRowSelect={this.onMenuSelect}>

            <Column field="resource_id" header="ID" />
            <Column field="resource_name" header="Name" />
            <Column field="resource_url_path" header="Path" />
            <Column field="resource_parent_id" header="Parent" />
            <Column field="resource_category_only" header="Category Only" />
            <Column field="resource_show_in_menu_verbal" header="Show In Menu" />

          </DataTable>
        </div>
      );
    }
    else return (<div></div>);
  }
};
import React, { Component, Fragment } from 'react';
import { InputText } from 'primereact/inputtext';
import { FilterInput } from '../../../Components/FormComponents/FilterInput';
import { LookupDropdown } from '../../../Components/FormComponents/LookupDropdown';
import { BooleanDropdown } from '../../../Components/FormComponents/BooleanDropdown';
import uuid from 'react-uuid';

/**
 * Acts as a factory and data collector for the plan editor
 */
export class PlanField extends Component {
  
  constructor( props ){
    super( props );

    this.state = {
      value: this.props.value
    }

    this.handleValueChange = this.handleValueChange.bind( this );
    this.handleOnBlur = this.handleOnBlur.bind( this );
  }

  handleValueChange( value ){
    this.setState( {value:value} );
  }

  handleOnBlur(){
    let payload = {
      value: this.state.value,
      field: this.props.field
    };
    this.props.onChange(payload);
  }

  genTermOptions( terms ){
    let optionList = [];

    if( terms )
      terms.map( (term) => optionList.push( term.term ) );

    return optionList;
  }
  
  render(){
    const val = (this.state.value ? this.state.value : "");

    switch( this.props.field.type ) {
      case "lookup":
        return (
          <div>
            <b>{this.props.field.label}:</b><br />
            <LookupDropdown
              tableName={this.props.field.table}
              labelField={this.props.field.labelField}
              valueField={this.props.field.valueField}
              value={this.state.value}
              onChange={(value) => this.handleValueChange(value)}
              onBlur={this.handleOnBlur}
              placeholder="Select or Enter a Term"
              disabled={this.props.disabled} />
          </div>
        );

      case "string":
        let inputField = null;
        if( this.props.field.table ){
          inputField = (
            <FilterInput
              tableName={this.props.field.table}
              labelField={this.props.field.labelField}
              value={this.props.value} 
              dataListName={this.props.dataListName} 
              onChange={(value) => this.handleValueChange(value)}
              onBlur={this.handleOnBlur}
              disabled={this.props.disabled} />
          );
        }
        else{
          const terms = this.genTermOptions(this.props.optionList);

          inputField = (
            <Fragment>
              <InputText 
                value={val} 
                list={this.props.dataListName} 
                onChange={(e) => this.handleValueChange(e.target.value)} 
                onBlur={this.handleOnBlur}
                disabled={this.props.disabled} />
              <datalist id={this.props.dataListName}>
                {terms.map((opt) => (<option value={opt} key={uuid()} />))}
              </datalist>
            </Fragment>
          );
        }
        return (
          <div>
            <b>{this.props.field.label}:</b><br />
            {inputField}
          </div>
        );
      
      case "boolean":
        return(
          <div>
            <b>{this.props.field.label}:</b><br />
            <BooleanDropdown id={this.props.id} value={val} label="Select Yes or No" onChange={(value) => this.handleValueChange( value )} disabled={this.props.disabled} />
          </div>
        );

      case "blank":
        return (<div className="p-col-6" >&nbsp;</div>);
      

    }
  }
}
import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { ToggleButton} from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { BooleanDropdown } from '../../Components/FormComponents/BooleanDropdown';
import { LookupDropdown } from '../../Components/FormComponents/LookupDropdown';

export class UserEditor extends React.Component {

  constructor( props ){
    super( props );

    this.state = {
      user: {first_name:"", last_name:"", username:"", email:"", password:"", role_id:this.props.defaultRole, status_id:this.props.defaultStatus, change_pw_flag:true}
    }

    if( this.props.user )
      this.setState( {user:this.props.user} );

    this.onCancelClick = this.onCancelClick.bind( this );
    this.onSave = this.onSave.bind( this );
    this.updateProperty = this.updateProperty.bind( this );
  }

  componentDidUpdate( prevProps ){
    if( this.props.user != prevProps.user ){
      if( !this.props.user ){
        this.setState(
          { user: { first_name: "", last_name: "", username: "", email: "", password: "", role_id: this.props.defaultRole, status_id: this.props.defaultStatus, change_pw_flag: true } }
        );
      }
      else{
        this.setState( {user: this.props.user} );
      }
    }
  }

  updateProperty( name, value ){
    let user = this.state.user;

    if( !user )
      user = {};
    
    user[name] = value;


    this.setState( {user: user} );
  }

  onSave(){
    this.props.onSaveUser( this.state.user );
  }

  onCancelClick(){
    this.props.onCloseInspector();
  }

  render(){
    let panelHeader = "System User";
    // let statusSel = "3"; // probably shouldn't hardcode
    // let roleSel = "5";
    let statusSel = this.state.user.status_id;
    let roleSel = this.state.user.role_id;
    let reqPwChange = this.state.user.change_pw_flag;

    if (this.state.user.status_id && this.state.user.status_id > 0 ){
      statusSel=this.state.user.status_id;
      console.log( "status:"+statusSel );
    }

    if (this.state.user.role_id && this.state.user.role_id > 0 ){
      roleSel=this.state.user.role_id;
      console.log( "role:"+roleSel );
    }

    if( this.state.user.change_pw_flag ){
      if( this.state.user.change_pw_flag == "0" ) reqPwChange = false;
      else if (this.state.user.change_pw_flag == "1") reqPwChange = true;
    }

    return(
      <div>
        <Panel header={panelHeader} toggleable={false}>
          <div className="p-grid p-fluid">

            <div className="p-col-5" style={{ padding: '.75em' }}><label htmlFor="first_name">First Name</label></div>
            <div className="p-col-7" style={{ padding: '.5em' }}>
              <InputText id="first_name" onChange={(e) => { this.updateProperty('first_name', e.target.value) }} value={this.state.user.first_name} />
            </div>

            <div className="p-col-5" style={{ padding: '.75em' }}><label htmlFor="last_name">Last Name</label></div>
            <div className="p-col-7" style={{ padding: '.5em' }}>
              <InputText id="last_name" onChange={(e) => { this.updateProperty('last_name', e.target.value) }} value={this.state.user.last_name} />
            </div>

            <div className="p-col-5" style={{ padding: '.75em' }}><label htmlFor="username">Username</label></div>
            <div className="p-col-7" style={{ padding: '.5em' }}>
              <InputText id="username" onChange={(e) => { this.updateProperty('username', e.target.value) }} value={this.state.user.username} />
            </div>

            <div className="p-col-5" style={{ padding: '.75em' }}><label htmlFor="email">Email</label></div>
            <div className="p-col-7" style={{ padding: '.5em' }}>
              <InputText id="email" onChange={(e) => { this.updateProperty('email', e.target.value) }} value={this.state.user.email} />
            </div>

            <div className="p-col-5" style={{ padding: '.75em' }}><label htmlFor="password">Temporary Password</label></div>
            <div className="p-col-7" style={{ padding: '.5em' }}>
              <InputText id="password" onChange={(e) => { this.updateProperty('password', e.target.value) }} value="" />
            </div>
          </div>
            <Accordion>
              <AccordionTab header="Security Settings">
                <div className="p-grid p-fluid">
                  <div className="p-col-5" style={{ padding: '.75em' }}><label htmlFor="status">Initial Status</label></div>
                  <div className="p-col-7" style={{ padding: '.5em' }}>
                    <LookupDropdown id="status" tableName="status_lu" labelField="name" valueField="status_id" value={statusSel} onChange={(e) => { this.updateProperty('status_id', e.target.value) }} />
                  </div>
                
                  <div className="p-col-5" style={{ padding: '.75em' }}><label htmlFor="role">User Role</label></div>
                  <div className="p-col-7" style={{ padding: '.5em' }}>
                    <LookupDropdown id="role" tableName="roles" labelField="name" valueField="role_id" value={roleSel} onChange={(e) => { this.updateProperty( 'role_id', e.target.value ) }} />
                  </div>

                  <div className="p-col-5" style={{ padding: '.75em' }}><label htmlFor="requireChange">Require Password Change</label></div>
                  <div className="p-col-7" style={{ padding: '.5em' }}>
                    <ToggleButton id="requireChange" offLabel="No" onLabel="Yes" checked={reqPwChange} onChange={(e) => { this.updateProperty('change_pw_flag', e.target.value) }} />
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          <div className="p-grid">
            <div className="p-col-6"><Button label="Save" icon="pi pi-check" onClick={this.onSave} /></div>
            <div className="p-col-6" style={{textAlign: "right"}}><Button label="Cancel" icon="pi pi-times" onClick={this.onCancelClick} className="p-button-secondary" /></div>
          </div>
        </Panel>
      </div>
    );
  }
}
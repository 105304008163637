import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { statsService } from '../../Service/statsservice.js';

export class DbStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      data: null
    }
  }

  componentDidMount() {
    statsService.getStats()
      .then(response => {
        console.log(response.data);
        this.setState({ isLoaded: true, data: response.data })
      }
      )
      .catch(error => console.log(error));
  }

  render(){
    if (this.state.isLoaded) {
      let tableList = this.state.data.libraryStats.map(table => {
        return (<React.Fragment key={table.tablename}><div className="p-col-12 p-grid"><div className="p-col-4">{table.tablename}: </div><div className="p-col-6">{table.records} records</div></div></React.Fragment>);
      });

      return (
        <Panel header="System Stats" toggleable={true}>
          <div className="p-grid p-fluid">
            <h2 className="p-col-12" style={{ margin: '10px 0px 0px 0px', padding: '0px' }}>Database Statistics for V2</h2>
            <div className="p-col-12 p-grid">
              <div className="p-col-4">Database Size: </div>
              <div className="p-col-6">{this.state.data.dbStats.db_size}MB</div></div>
            <h3 className="p-col-12" style={{ margin: '10px 0px 0px 0px', padding: '0px' }}>Library Record Stats</h3>
            {tableList}
          </div>
        </Panel>
      );
    }
    else {
      return (<div></div>);
    }
  }
}
import {config as Config} from '../Config/config';
import axios from 'axios';

export const statsService = {
	getStats
};

function getStats( aIds ){
	let strUrl = Config.apiUrl + "?res=stats";
	console.log( strUrl );

	return axios.get( strUrl );
}
import { config as Config } from '../Config/config';
import axios from 'axios';

export const filterService = {
	getFilterData,
	getCarriers,
	getTrusts,
	getPlanDates,
	getNetworks,
  getPlans,
  getPlanIds,
  getTerms
};

function getFilterData( filterObj ){
	switch( filterObj.op ){
		case "carriers":
			return getCarriers();

		case "trusts":
			return getTrusts( filterObj.carrier );

		case "plandates":
			return getPlanDates( filterObj.carrier, filterObj.trust );

		case "networks":
			return getNetworks( filterObj.carrier, filterObj.trust, filterObj.plandate );

		case "plans":
			return getPlanIds( filterObj.carrier, filterObj.trust, filterObj.plandate, filterObj.network );
		default:
			return null;
	}
}

function getCarriers(){
	let args = "&op=carriers&state=" + Config.state + "&groupsize=" + Config.groupsize;
	let strUrl = Config.apiUrl + "?res=filter" + args;
	console.log( strUrl );

	return axios.get( strUrl );
}

function getTrusts( carrier ){
	let args = "&op=trusts&state=" + Config.state + "&groupsize=" + Config.groupsize;
	args += "&carrier=" + carrier;

  let strUrl = Config.apiUrl + "?res=filter" + args;
	console.log( strUrl );

	return axios.get( strUrl );
}

function getPlanDates( carrier, trust ){
	let args = "&op=PlanDates&state=" + Config.state + "&groupsize=" + Config.groupsize;
	args += "&carrier=" + carrier + "&trust=" + trust;

  let strUrl = Config.apiUrl + "?res=filter" + args;
	console.log( strUrl );

	return axios.get( strUrl );
}

function getNetworks( carrier, trust, plandate ){
	let args = "&op=networks&state=" + Config.state + "&groupsize=" + Config.groupsize;
	args += "&carrier=" + carrier + "&trust=" + trust + "&plandate=" + plandate;

  let strUrl = Config.apiUrl + "?res=filter" + args;
	console.log( strUrl );

	return axios.get( strUrl );
}

function getPlanIds( carrier, trust, plandate, network ){
  let args = "&op=PlanIds&state=" + Config.state + "&groupsize=" + Config.groupsize;
  args += "&carrier=" + carrier + "&trust=" + trust + "&plandate=" + plandate + "&network=" + encodeURIComponent(network);

  let strUrl = Config.apiUrl + "?res=filter" + args;
  console.log(strUrl);

  return axios.get(strUrl);
}

function getPlans( carrier, trust, plandate, network ){
	let args = "&op=PlansDetailed&state=" + Config.state + "&groupsize=" + Config.groupsize;
	args += "&carrier=" + carrier + "&trust=" + trust + "&plandate=" + plandate + "&network=" + encodeURIComponent( network );

  let strUrl = Config.apiUrl + "?res=filter" + args;
	console.log( strUrl );

	return axios.get( strUrl );
}

function getTerms() {
  let strUrl = Config.apiUrl + "?res=db&sub=terms";
  console.log(strUrl);

  return axios.get(strUrl);
}

import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { menuService } from '../../Service/MenuService';

import { Login } from '../Login/Login';
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
import { Dashboard } from './Dashboard';
import PlanManager from '../PlanManager/planmanager';
import UserManager from '../UserManager/UserManager';
import MenuManager from '../ResourceManager/ResourceManager';
import CarrierManager from '../PlanDataManager/CarrierManager/CarrierManager';
import TrustManager from '../PlanDataManager/TrustManager/TrustManager';
import NetworkManager from '../PlanDataManager/NetworkManager/NetworkManager';
import PlanTypeManager from '../PlanDataManager/PlanTypeManager/PlanTypeManager';
import PlanTermManager from '../PlanDataManager/PlanTermManager/PlanTermManager';

import { Account } from './Account.js'

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../../Layout/layout.scss';
import '../../App.scss';

class Home extends Component {

  constructor() {
    super();
    this.state = {
      layoutMode: 'static',
      layoutColorMode: 'dark',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: null,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    // this.createMenu();
  }

  componentDidMount(){
    menuService.getMenu()
      .then( (resp) => {this.setState( {menu: resp.menu} );} )
      .catch( (err) => {console.log( err )} );
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === 'overlay') {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive
        });
      }
      else if (this.state.layoutMode === 'static') {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive
        });
      }
    }
    else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false
      })
    }
  }

  addClass(element, className) {
    if (element.classList)
      element.classList.add(className);
    else
      element.className += ' ' + className;
  }

  removeClass(element, className) {
    if (element.classList)
      element.classList.remove(className);
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  render() {
    console.log( this.props.user );
    if( !this.props.isLoggedIn ) {
      return <Redirect to="/login" />
    }

    let layoutMain = null;

    // if( this.props.user.change_pw_flag === "1" ){
    //   layoutMain = (
    //     <div className="layout-main">
    //       <Account title="Password Change Required" />
    //     </div>
    //   );
    // }
    // else{
      layoutMain = (
        <div className="layout-main">
          <Route exact path="/" render={() => <Dashboard />} />
          <Route path="/planmanager" render={() => <PlanManager />} />
          <Route path="/users" render={() => <UserManager />} />
          <Route path="/menu" render={() => <MenuManager />} />
          <Route path="/carriers" render={() => <CarrierManager />} />
          <Route path="/trusts" render={() => <TrustManager />} />
          <Route path="/networks" render={() => <NetworkManager />} />
          <Route path="/plantypes" render={() => <PlanTypeManager />} />
          <Route path="/planterms" render={() => <PlanTermManager />} />
          <Route path="/account" render={() => <Account />} />
          <Route path="/login" render={() => <Login />} />
        </div>
      );
    //}
    
    document.title = "TBA Admin Utilities";

    // const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg': 'assets/layout/images/logo.svg';

    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive
    });

    const sidebarClassName = classNames("layout-sidebar", {
      'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
      'layout-sidebar-light': this.state.layoutColorMode === 'light'
    });

    return (
      <div className={wrapperClass}>
        <AppTopbar onToggleMenu={this.onToggleMenu} />

        <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
          <div className="layout-branding-text">
            <div>TBA Admin</div>
          </div>
          <AppProfile />
          <AppMenu model={this.state.menu} onMenuItemClick={this.onMenuItemClick} />
        </div>

          {/* <div className="layout-main">
            <Route exact path="/"  render={() => <Dashboard />} />
            <Route path="/planmanager" render={() => <PlanManager />} />
            <Route path="/users" render={() => <UserManager />} />
            <Route path="/menu" render={() => <MenuManager />} />
            <Route path="/carriers" render={() => <CarrierManager />} />
            <Route path="/trusts" render={() => <TrustManager />} />
            <Route path="/networks" render={() => <NetworkManager />} />
            <Route path="/plantypes" render={() => <PlanTypeManager />} />
            <Route path="/planterms" render={() => <PlanTermManager />} />
            <Route path="/account" render={() => <Account />} />
            <Route path="/login" render={() => <Login />} />
          </div> */}
          {layoutMain}

        {/*<AppFooter />*/}

        <div className="layout-mask"></div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const{ isLoggedIn, user } = state.authentication;
  return {
   isLoggedIn,
   user
  };
}

const connectedHomePage = connect(mapStateToProps)(Home);
export { connectedHomePage as Home };

import React, { Component } from 'react';
import { UAParser } from 'ua-parser-js';
import { Panel } from 'primereact/panel';

export class LoginStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      data: null
    }
  }

  getBrowserData(uaString) {
    // process ua string
    let parser = new UAParser();
    parser.setUA(uaString);
    let uaResult = parser.getResult();

    return uaResult;
  }

  render() {
    const { browser, os, device } = this.getBrowserData(this.props.user.last_user_agent);

    return (
      <Panel header="Previous Login Details" toggleable={true}>
        <ul className="detail-list">
          <li><b>Last Logged In</b>: {this.props.user.last_login}</li>
          <li><b>IP Address</b>: {this.props.user.last_ip}</li>
          <li><b>Browser</b>: {browser.name} {browser.version}</li>
          <li><b>Operating System</b>: {os.name} {os.version}</li>
          <li><b>Device</b>: {device.vendor}</li>
        </ul>
      </Panel>
    );

  }
}
import { filterConstants } from '../Constants/filter.constants';

/*export const filterActions = {
	selectCarrier,
	selectTrust,
	selectNetwork,
	selectMetal,
	selectPlanType
};*/

export function selectCarrier( carrier ){ return { type:filterConstants.SELECT_CARRIER, carrier } }
export function selectTrust( trust ){ return { type:filterConstants.SELECT_TRUST, trust } }
export function selectPlanDate( plandate ){ return { type:filterConstants.SELECT_PLANDATE, plandate } }
export function selectNetwork( network ){ return { type:filterConstants.SELECT_NETWORK, network } }


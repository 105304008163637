import React, { Component } from 'react';
// import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// import { Messages } from 'primereact/messages';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { history } from './Helpers/history';
import { alertActions } from './Actions/alert.actions';
// import { PrivateRoute } from './components/routes/PrivateRoute';
import { Home } from './Containers/Home/Home';
import { Login } from './Containers/Login/Login';

import './Layout/layout.scss';
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen( ( location, action ) => {
      // clear alert on location change
      dispatch( alertActions.clear() );
    }); 
  }

  render() {
    const { alert } = this.props;
    console.log(alert);

    if( this.props.isLoggedIn ){
      console.log( "logged in" );
      return ( <Home /> );
    }
    else{
      console.log( "not logged in" );
      return ( <Login /> );
    }
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { isLoggedIn } = state.authentication;
  return {
    alert,
    isLoggedIn
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
